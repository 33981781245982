import React from "react";
import App from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import "../styles/index.scss";
import "../styles/utils.scss";
import { MonetateProvider } from "@/context/MonetateContext";

const siteName = "John Lewis & Partners";

const CustomApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const meta = Component.generateMetadata?.(router.query) || Component.metadata || {};
  const pageTitle = `${meta?.title || "404 - Page not found"} | ${siteName}`;
  const pageDescription = meta?.description;
  const url = `https://${process.env.NEXT_PUBLIC_VENDOR_HOST}${router.basePath}${
    router.asPath === "/" ? "" : router.asPath
  }`;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:image:alt" content={`${pageTitle} | ${siteName}`} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="environment_name" content={process.env.NEXT_PUBLIC_ENVIRONMENT_NAME} />
        <meta name="robots" content="INDEX, FOLLOW" />
      </Head>
      <main id="main" className="main">
        <MonetateProvider experiences={pageProps.experiences || []}>
          <Component {...pageProps} />
        </MonetateProvider>
      </main>
      <div id="page-root" />
    </>
  );
};

CustomApp.getInitialProps = appContext => {
  return App.getInitialProps(appContext);
};

export default CustomApp;
